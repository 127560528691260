<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
    >
      <dashboard-congratulation></dashboard-congratulation>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <dashboard-statistics-card></dashboard-statistics-card>
    </v-col>

    <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <dashboard-weekly-overview></dashboard-weekly-overview>
    </v-col>

    <v-col
      cols="12"
      md="4"
      sm="6"
    >
      <dashboard-card-total-earning></dashboard-card-total-earning>
    </v-col>

    <v-col
      cols="12"
      md="4"
    >
      <v-row class="match-height">
        <v-col
          cols="12"
          sm="6"
        >
          <statistics-card-vertical
            :change="totalDeposit.change"
            :color="totalDeposit.color"
            :icon="totalDeposit.icon"
            :statistics="totalDeposit.statistics"
            :stat-title="totalDeposit.statTitle"
            :subtitle="totalDeposit.subtitle"
          ></statistics-card-vertical>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <statistics-card-vertical
            :change="totalWithdrawal.change"
            :color="totalWithdrawal.color"
            :icon="totalWithdrawal.icon"
            :statistics="totalWithdrawal.statistics"
            :stat-title="totalWithdrawal.statTitle"
            :subtitle="totalWithdrawal.subtitle"
          ></statistics-card-vertical>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <statistics-card-vertical
            :change="totalInterrupt.change"
            :color="totalInterrupt.color"
            :icon="totalInterrupt.icon"
            :statistics="totalInterrupt.statistics"
            :stat-title="totalInterrupt.statTitle"
            :subtitle="totalInterrupt.subtitle"
          ></statistics-card-vertical>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <statistics-card-vertical
            :change="totalFees.change"
            :color="totalFees.color"
            :icon="totalFees.icon"
            :statistics="totalFees.statistics"
            :stat-title="totalFees.statTitle"
            :subtitle="totalFees.subtitle"
          ></statistics-card-vertical>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      cols="12"
    >
      <dashboard-card-deposit-withdraw-interrupt></dashboard-card-deposit-withdraw-interrupt>
    </v-col>
    <v-col cols="12">
      <dashboard-datatable></dashboard-datatable>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'

// demos
import DashboardStatisticsCard from './DashboardStatisticsCard.vue'
import DashboardCardTotalEarning from './DashboardCardTotalEarning.vue'
import DashboardCardDepositWithdrawInterrupt from './DashboardCardDepositWithdrawInterrupt.vue'
import DashboardWeeklyOverview from './DashboardWeeklyOverview.vue'
import DashboardDatatable from './DashboardDatatable.vue'
import DashboardCongratulation from './DashboardCongratulation.vue'

export default {
  components: {
    StatisticsCardVertical,
    DashboardCongratulation,
    DashboardStatisticsCard,
    DashboardCardTotalEarning,
    DashboardCardDepositWithdrawInterrupt,
    DashboardWeeklyOverview,
    DashboardDatatable,
  },
  setup() {
    const totalDeposit = {
      statTitle: 'Total Deposits',
      icon: mdiPoll,
      color: 'success',
      subtitle: 'Monthly Target (XAF)',
      statistics: '256.6k',
      change: '+42%',
    }

    const totalWithdrawal = {
      statTitle: 'Total Withdrawals',
      icon: mdiCurrencyUsd,
      color: 'secondary',
      subtitle: 'Monthly Target (XAF)',
      statistics: '178k',
      change: '-15%',
    }

    // vertical card options
    const totalInterrupt = {
      statTitle: 'Total Faults',
      icon: mdiLabelVariantOutline,
      color: 'error',
      subtitle: 'Monthly Target (XAF)',
      statistics: '862k',
      change: '-18%',
    }

    const totalFees = {
      statTitle: 'Total Profits',
      icon: mdiHelpCircleOutline,
      color: 'success',
      subtitle: 'Monthly Target (XAF)',
      statistics: '15k',
      change: '+18%',
    }

    return {
      totalDeposit,
      totalWithdrawal,
      totalInterrupt,
      totalFees,
    }
  },
   
  mounted(){
      document.title = "TopUp | Dashboard"
  },
}
</script>
